<template>
  <div class="new-signup">
    <HomeHeader />

    <div class="img">
      <img src="@/assets/images/pc/Inside/Inside2-pc.png" alt="" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";

import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "NewSignup",
  mixins: [elementHeight],

  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
.new-signup {
  font-size: 16px;

  img {
    width: 100%;
  }
}
</style>
